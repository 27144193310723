.modal-overlay {
    position: fixed;
    max-width: 940px;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    top: 0px;
    right: 0px;
    border: 1px solid #333840;
    background: #1A2033;
    display: flex;
    flex-direction: column;
    z-index: 1008;
    overflow: auto;
  }
  .modal-content {
    overflow: auto; /* Added to make the content scrollable */
  }
  .modal-content::-webkit-scrollbar {
    width: 5px;
  }
  
  .modal-content::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .modal-content::-webkit-scrollbar-thumb {
    background: #4A4F5E;
    border-radius: 15px;
  }
  
  .modal-content::-webkit-scrollbar-thumb:hover {
    background: #4A4F5E;
    box-shadow: inset 0 0 5px grey; 
  }
  

  .modal-overlay.open {
    /* Apply animation when modal enters */
    animation: roadRunnerIn 0.9s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  }
  
  .modal-overlay.out {
    /* Apply animation when modal exits */
    animation: roadRunnerOut 0.9s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  }
  
  @keyframes roadRunnerIn {
    0% {
      transform: translateX(-1200px) skewX(55deg) scaleX(0.5);
    }
    70% {
      transform: translateX(30px) skewX(0deg) scaleX(0.8);
    }
    100% {
      transform: translateX(0px) skewX(0deg) scaleX(1);
    }
  }
  
@keyframes roadRunnerOut {
    0% {
        transform: translateX(200px) translateY(-55px) skewX(0deg) skewY(-55deg) scaleX(-0.5);
    }
    30% {
        transform: translateX(-30px) translateY(0) skewX(0) skewY(-10deg) scaleX(0.9); /* Adjusted translation, skew, and scale */
    }
    100% {
        transform: translateX(0px) translateY(220px) skewX(0deg) skewY(20deg) scaleX(1); /* Adjusted translation, skew, and scale */
    }
}

.modal-content {
  position: relative;
  border-radius: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #1A2033;
  height: 100vh;
  flex-grow: 1;
  overflow: auto;
  overflow-x: hidden;
}

.carousel-wrapper {
  width: 100%;
  margin-left: 50px;
  margin-top: 20px;
  border-radius: 20px;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease-in-out;
  max-height: 500px;
  max-width: 930px;
  flex-direction: row;
}

.carousel-cell {
  flex: 0 0 40%; 
  margin-right: 5px;
  width: 68px;/* Ensure each cell takes the full width */
}

.carousel-cell img {
  width: 100%;
  height: 100%;
  border-radius: 0px;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  perspective: 1000px; /* Add perspective for 3D effect */
}

.side-images{
  position: inherit;
  width: 100px;
  height: 340px;
  overflow-y: auto; /* Add a scrollbar when the content exceeds the container's height */
  cursor: pointer;
  overflow-x: hidden;
}

.scrollbar4::-webkit-scrollbar {
  width: 3px;
}

.scrollbar4::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar4::-webkit-scrollbar-thumb {
  background: #4A4F5E;
  border-radius: 15px;
}

.scrollbar4::-webkit-scrollbar-thumb:hover {
  background: #4A4F5E;
  box-shadow: inset 0 0 5px grey; 
}

.side-images img {
  border-radius: 0px;
  width: 90px;
  height: 67px;
}

.descriptionContainer {
  max-height: 400px;
  width: 100%;
}

.contactContainer {
  height: 100px;
}

.imageContainer {
  display: flex;
  flex-direction: row;
  width: 440px;
}

.uploadIcon {
  font-size: 48p;
  color: '#ccc';
  cursor: pointer;
}

  input[type="checkbox"]{
    visibility: hidden;
    position: absolute;
  }
  input[type="checkbox"]:before{
    height:12px;
    width:12px;
    margin-right: 2px;
    content: " ";
    display:inline-block;
    vertical-align: baseline;
    border:1px solid #FFFFFF;
    background-color: #FFFFFF;
  }
  input[type="checkbox"]:checked:before{
    background-color: black;
  }
  input[type="checkbox"]:before{
    border-radius:50%;
  }
  
  .MuiGrid-container {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow:'scroll';
    overflow-x: hidden;
  }

  @media (max-width: 1050px) {
    .MuiGrid-container {
      gap: 7px;
    }
  }

  @media (max-width: 564px){
  /* Override styles for ListItem */

  }

  .card {
    perspective: 1000px;
    overflow: hidden;
    &:hover .card__inner {
      transform: rotateY(180deg);
    }
  }
  
  .card,
  .card__inner {
    position: relative;
    width: 228px;
    height: 193px;
  }
  
  .card__inner {
    transition: transform .5s ease-in-out;
    transform-style: preserve-3d;
  }
  
  .card__back {
    transform: rotateY(180deg);
  }
  
  .card__front,
  .card__back {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    color: transparent;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    backface-visibility: hidden;
  }
  
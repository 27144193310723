/* CalendarComponentStyles.css */
.rdrCalendarWrapper {
    background: linear-gradient(rgb(42, 47, 61) 0%, rgba(35, 40, 51, 0) 100%) !important;
    width: 100%;
  }
  
  :root {
    --calendar-width: 100%;
  }
  

  .rdrDateDisplayItem,
  .rdrDayNumber span,
  .rdrMonthAndYearWrapper {
    color: white !important;
  }

  .rdrMonth {
    width: var(--calendar-width);
  }

  .rdrMonthPicker, .rdrYearPicker {
    margin: 0;
  }
  .rdrDateDisplayItemActive input {
    color: black;
  }

  .rdrDateDisplayItem input {
    color: black;
    font-family: Montserrat;
  }
  .rdrMonthAndYearPickers {
    width: 5px;
  }
  
  .rdrDateDisplayWrapper {
    background-color: transparent;
  }

  .rdrNextPrevButton {
    background: transparent
  }

  .rdrNextPrevButton:hover {
    background: transparent;
  }

  .rdrPprevButton i {
    border-color: transparent rgb(255, 255, 255) transparent transparent
  }

  .rdrPprevButton i {
    transform: translate(-13px, 0px)
  }

  .rdrNextButton i {
    transform: translate(13px, 0px)
  }

  .rdrNextButton i {
    border-color: transparent transparent transparent rgb(255, 255, 255)
  }

  .rdrMonthAndYearPickers select {
    color: white;
    background-color: transparent;
  }

  .rdrMonthAndYearPickers select:hover {
    background-color: transparent;
    color: rgb(0, 208, 189);
  }

  .rdrMonthAndYearPickers select option {
    background-color: rgb(31, 44, 65); /* This might not work as expected in all browsers */
  }

  .rdrDateDisplayItemActive {
    background-color: transparent
  }

  .rdrDateDisplayItem {
    background-color: transparent;
    border: 1px solid white
    
  }

  .rdrDateDisplayItem input {
    color: white
  }
  
  
.scrollbar4::-webkit-scrollbar {
    width: 7px;
  }
  
  .scrollbar4::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .scrollbar4::-webkit-scrollbar-thumb {
    background: #4A4F5E;
    border-radius: 15px;
  }
  
  .scrollbar4::-webkit-scrollbar-thumb:hover {
    background: #4A4F5E;
    box-shadow: inset 0 0 5px grey; 
  }
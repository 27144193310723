@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #0E1529;
}

.container {
  padding: 40px;
  border-radius: 20px;
  border: 8px solid #0E1529;
  box-shadow: -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.35),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    inset 5px 5px 15px rgba(0, 0, 0, 0.35);
}

.container .form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
}

.container .form.signin,
.container.signinForm .form.signup {
  display: none;
}

.container.signinForm .form.signin {
  display: flex;
}

.container .form h2 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.container .form .inputBox {
  position: relative;
  width: 300px;
  margin-bottom: 10px;
}

.container .form .inputBox input {
  padding: 12px 10px 12px 48px;
  border: none;
  width: 100%;
  background: #223243;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #fff;
  font-weight: 300;
  border-radius: 25px;
  font-size: 1em;
  box-shadow: -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.35);
  transition: 0.5s;
  outline: none;
}

.container .form .inputBox span {
  position: absolute;
  left: 0;
  padding: 12px 10px 12px 48px;
  pointer-events: none;
  font-size: 1em;
  font-weight: 300;
  transition: 0.5s;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.5);
}

.container .form .inputBox input:valid ~ span,
.container .form .inputBox input:focus ~ span {
  color: #00D0BD;
  border: 1px solid #00D0BD;
  background: #223243;
  transform: translateX(25px) translateY(-7px);
  font-size: 0.6em;
  padding: 0 8px;
  border-radius: 10px;
  letter-spacing: 0.1em;
}

.container .form .inputBox input:valid,
.container .form .inputBox input:focus {
  border: 1px solid #223243;
}

.container .form .inputBox i {
  position: absolute;
  top: 15px;
  left: 16px;
  width: 25px;
  padding: 2px 0;
  padding-right: 8px;
  color: #00D0BD;
  border-right: 1px solid #00D0BD;
}

.container .form .inputBox input[type="submit"] {
  color: white;
  padding: 10px 0;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

.container .form .inputBox input[type="submit"]:hover {
  background: #00D0BD;
  color: #223243;
}

.container .form p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.75em;
  font-weight: 300;
}

.container .form p .login {
  font-weight: 500;
  color: #fff;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
}

.container .form p .create {
    font-weight: 500;
    color: #fff;
    border: none;
    background-color: transparent;
    cursor: pointer;
    text-decoration: underline;
}

.box{
    margin: 0px 10px 0px 20px;
    width: 200px;
    height: 70px;

  }
  
.container-2{
    width: 200px;
    vertical-align: middle;
    white-space:nowrap;
    position:relative;
  }
  
.container-2 input#search{
  width: 200px; /* set the width to 200px */
  height: 50px;
  background: transparent;
  border:none;
  font-size: 10pt;
  float: left;
  color: #262626;
  padding-left: 40px;
  border-radius: 15px;
  color: #fff;
  border: 1px solid #2b303b;
}

.container-2 input#search::-webkit-input-placeholder {
  color: white;
}

.container-2 input#search:-moz-placeholder { /* Firefox 18- */
  color: #65737e;  
}

.container-2 input#search::-moz-placeholder {  /* Firefox 19+ */
  color: #65737e;  
}

.container-2 input#search:-ms-input-placeholder {  
  color: #65737e;  
}

.container-2 .icon{
  position: absolute;
  top: 50%;
  left: 1%;
  margin-left: 17px;
  margin-top: 13px;
  z-index: 1;
  color: #4f5b66;
}

.container-2 input#search:focus, .container-2 input#search:active{
  outline:none;
}

.container-2 .icon{
  color: rgb(0, 208, 189);
}

/* Style the custom clear button */
.container-2 input[type="search"]::-webkit-search-cancel-button {
  /* Add your custom styles for the clear button here */
  font-size: 20px;
  cursor: pointer;
  color: red ;
  -webkit-appearance: none;
  border: none; /* Remove border */
  width: 20px; /* Adjust width */
  height: 20px; /* Adjust height */
  content: url('../img/XCircleSearch.png'); /* Set background image */
  background-size: cover; /* Adjust background size */
  cursor: pointer; /* Set cursor to pointer */
  position: absolute; /* Position the button */
  top: 50%; /* Adjust vertical position */
  right: 10px; /* Adjust horizontal position */
  transform: translateY(-50%); /* Center vertically */
}
  .scrollbar3::-webkit-scrollbar {
    width: 3px;
  }
  
  .scrollbar3::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .scrollbar3::-webkit-scrollbar-thumb {
    background: #4A4F5E;
    border-radius: 15px;
  }
  
  .scrollbar3::-webkit-scrollbar-thumb:hover {
    background: #4A4F5E;
    box-shadow: inset 0 0 5px grey; 
  }

  .sideImages {
    width: 100px; 
    height: 340px; 
    overflow-y: scroll;
    position: relative;
    left: 0.5%;
    overflow-x: hidden;
    cursor: pointer;
  }

  .scrollbar6::-webkit-scrollbar {
    width: 5px;
  }
  
  .scrollbar6::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .scrollbar6::-webkit-scrollbar-thumb {
    background: #4A4F5E;
    border-radius: 15px;
  }
  
  .scrollbar6::-webkit-scrollbar-thumb:hover {
    background: #4A4F5E;
    box-shadow: inset 0 0 5px grey; 
  }
  .dialogContent {
    position: fixed;
    max-width: 940px;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    top: 0px;
    right: 0px;
    border: 1px solid #333840;
    background: #1A2033;
    display: flex;
    flex-direction: column;
    z-index: 1008;
    margin: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .dialogContent::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .dialogContent::-webkit-scrollbar-thumb {
    background: #4A4F5E;
    border-radius: 15px;
  }
  
  .dialogContent::-webkit-scrollbar-thumb:hover {
    background: #4A4F5E;
    box-shadow: inset 0 0 5px grey;
  }
  
  .dialogContent {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .assign-dialog-menu {
    left: 839px !important;
  }

  .transparent-input {
    background-color: transparent !important;
    border: 1px solid #2b303b; /* Optional: removes the border */
    border-right: none; 
    border-left: none;
    border-top: none; 
    color: white;
    outline: none;
    font-size: 1rem;
    font-family: 'Montserrat';
    font-weight: 400;
    cursor: pointer;
  
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
  }
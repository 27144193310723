/* First Scrollbar */
.scrollbar1::-webkit-scrollbar {
  width: 3px;
}

.scrollbar1::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar1::-webkit-scrollbar-thumb {
  background: transparent;
}

.scrollbar1::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Second Scrollbar */

.scrollbar2::-webkit-scrollbar {
  width: 7px;
}

.scrollbar2::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar2::-webkit-scrollbar-thumb {
  background: #4A4F5E;
  border-radius: 15px;
}

.scrollbar2::-webkit-scrollbar-thumb:hover {
  background: #4A4F5E;
  box-shadow: inset 0 0 5px grey; 
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}

@media (max-width: 400px) {
  .scrollbar1 {
    height: 90vh !important;
  }
}
.edit-container {
  display: flex;
}

.edit-container .switch {
  margin-left: 0px;
  cursor: pointer;
}
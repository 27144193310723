.calendar-container .rbc-header, /* day headers */
.calendar-container .rbc-event, /* event titles */
.calendar-container .rbc-toolbar-label, /* calendar title */
.calendar-container .rbc-row-content .rbc-row /* day numbers */ {
  color: white;
}

.calendar-container .rbc-off-range-bg {
    background: dimgray;
}
.calendar-container .rbc-month-view .rbc-today {
    background-color: rgb(0, 208, 189);
}
.calendar-container .rbc-today {
    background-color: rgb(0, 208, 189);
}

.rbc-timeslot-group {
    min-height: 100px!important;

}

.rbc-day-slot .rbc-time-slot {
    border-top: '0px'!important;
}
.merged-container .rbc-today {
    background-color: transparent!important;
}

.rbc-header { 
    white-space: normal!important;
}
.rbc-time-view .rbc-row {
    min-height: 40px!;
}
.rbc-time-header-cell {
    height: 70px!important;
}

.rbc-time-header-content {
    border-left: 1px solid #ddd;
}
.rbc-time-header.rbc-overflowing {
    margin-right: 6px!important;
}
.rbc-day-slot .rbc-time-slot {
    border-top: 0px!important;
}
.calendar-container .rbc-time-view .rbc-today,
.calendar-container .rbc-day-view .rbc-today {
    background-color: transparent !important;
}
.calendar-container {
    padding-left: 20px;
    padding-right: 20px;
}

.calendar-container .rbc-event, .rbc-day-slot .rbc-background-event {
    background-color: #5DCDBD;
}
.calendar-container .rbc-toolbar button {
    color: white;
}
.calendar-container .rbc-month-view {
    background-color: #262A39;
}

.calendar-container .rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    background-color: rgb(0, 208, 189);
}

.calendar-container .rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
    background-color: rgb(0, 208, 189) !important;
    cursor: pointer;
}

.calendar-container .rbc-agenda-empty {
    color: white;
}
.calendar-container .rbc-toolbar button:hover {
    background-color:  rgb(0, 208, 189) !important;
    cursor: pointer;
}

.calendar-container .rbc-toolbar button:focus {
    background-color: transparent;
    color: white;
}

.calendar-container .rbc-show-more {
    color: white;
    cursor: pointer;
}

.calendar-container .rbc-show-more:hover {
    color: rgb(0, 208, 189);
}

.calendar-container .rbc-event.rbc-selected {
    background-color: rgb(0, 208, 189);
}

.calendar-container .rbc-agenda-view table.rbc-agenda-table {
    color: white;
}

.calendar-container .rbc-label {
    color: white;
}

.calendar-container .rbc-toolbar button.rbc-active {
    background-color: rgb(0, 208, 189);
}


.merged-container .rbc-header, /* day headers */
.merged-container .rbc-event, /* event titles */
.merged-container .rbc-toolbar-label, /* calendar title */
.merged-container .rbc-row-content .rbc-row /* day numbers */ {
  color: white;
}
.merged-container .rbc-row {
    height: 20px;
}
.rbc-row-content {
    height: 0px;
}
.merged-container .rbc-off-range-bg {
    background: dimgray;
}
.merged-container .rbc-today {
    background-color: rgb(0, 208, 189);
}

.merged-container .rbc-month-view .rbc-today {
    background-color: rgb(0, 208, 189);
}

.merged-container .rbc-event, .rbc-day-slot .rbc-background-event {
    background-color: #5DCDBD;
}
.merged-container {
    padding-left: 20px;
    padding-right: 20px;
}

.merged-container .rbc-toolbar button {
    color: white;
}

.merged-container .rbc-month-view {
    background-color: #262A39;
}

.merged-container .rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    background-color: rgb(0, 208, 189);
}

.merged-container .rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
    background-color: rgb(0, 208, 189) !important;
    cursor: pointer;
}

.merged-container .rbc-agenda-empty {
    color: white;
}
.merged-container .rbc-toolbar button:hover {
    background-color:  rgb(0, 208, 189) !important;
    cursor: pointer;
}

.merged-container .rbc-toolbar button:focus {
    background-color: transparent;
    color: white;
}

.merged-container .rbc-show-more {
    color: white;
    cursor: pointer;
}

.merged-container .rbc-show-more:hover {
    color: rgb(0, 208, 189);
}

.merged-container .rbc-event.rbc-selected {
    background-color: rgb(0, 208, 189);
}

.merged-container .rbc-agenda-view table.rbc-agenda-table {
    color: white;
}

.merged-container .rbc-label {
    color: white;
}

.merged-container .rbc-toolbar button.rbc-active {
    background-color: rgb(0, 208, 189);
}

.merged-container .rbc-time-content > .rbc-day-slot {
    background: transparent;
}

.rbc-time-content {
    border-top: 1px solid #ddd!important;
}
  
  .rbc-time-content::-webkit-scrollbar {
    width: 6px;
  }
  
  .rbc-time-content::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .rbc-time-content::-webkit-scrollbar-thumb {
    background: #4A4F5E;
    border-radius: 15px;
  }
  
  .rbc-time-content::-webkit-scrollbar-thumb:hover {
    background: #4A4F5E;
    box-shadow: inset 0 0 5px grey;
  }
  
input[type=time]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    filter: invert(1);
}

